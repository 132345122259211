import { FC, useLayoutEffect, useState } from 'react'
import { logoSrc } from '../assets'
import useWindowDimensions from '../hooks/useWindowDimensions'

const Splash: FC<{ loaded: boolean, onCanLoad: () => void }> = ({ loaded, onCanLoad }) => {
  const [contentOpacity, setContentOpacity] = useState(0)
  const dim = useWindowDimensions()

  const className = ['w-screen bg-white fixed z-[10000000000000] flex flex-col transition-all duration-500 items-center justify-center', loaded ? 'opacity-0 pointer-events-none' : 'opacity-100'].join(' ')

  useLayoutEffect(() => {
    setTimeout(() => {
        setContentOpacity(1)
        onCanLoad()
    }, 500)
  }, [])

  return (
    <div className={className} style={{...dim}}>
      <div className="flex flex-col items-center justify-center transition-all duration-500" style={{ opacity: contentOpacity, transform: `scale(${Math.min(1, (dim.width-32) / 400)})` }}>
        <img src={logoSrc} className="w-[40%] h-[40%] object-contain"/>
        <div className="h-3 w-4/5 rounded-full bg-green mt-[78px]" />
        <span className="your-first-text mt-16">
          Your first <span className="font-semibold text-green">AI</span> medical doctor
        </span>
      </div>
    </div>
  )
}

export default Splash
