export const symptomIds = [
    "abdominal_cramps",
    "abdominal_pain",
    "acne",
    "agitation",
    "anemia",
    "anxiety",
    "appetite_loss",
    "back_pain",
    "bad_breath",
    "black_or tarry stools",
    "bleeding_gums",
    "bloating",
    "blurred_vision",
    "breast_lump",
    "breast_pain",
    "bruising",
    "burning_urination",
    "changes_in bowel habits",
    "chest_discomfort",
    "chest_pain",
    "chills",
    "cold_intolerance",
    "confusion",
    "constipation",
    "cough",
    "cramps",
    "decreased_sweating",
    "delayed_wound healing",
    "depression",
    "diarrhea",
    "difficulty_breathing",
    "difficulty_swallowing",
    "discharge",
    "dizziness",
    "double_vision",
    "dry_mouth",
    "dry_skin",
    "ear_pain",
    "eczema",
    "edema",
    "emotional_instability",
    "enlarged_lymph nodes",
    "enlarged_thyroid gland",
    "excessive_thirst",
    "excessive_urination",
    "eye_pain",
    "facial_pain",
    "fainting",
    "fatigue",
    "fever",
    "fingernail_changes",
    "flushing",
    "frequent_infections",
    "frequent_urination",
    "gas",
    "genital_rash",
    "hair_loss",
    "hallucinations",
    "headache",
    "heart_palpitations",
    "hearing_loss",
    "heartburn",
    "hiccups",
    "high_blood pressure",
    "high_blood sugar",
    "high_cholesterol",
    "hoarseness",
    "hot_flashes",
    "hyperactivity",
    "hyperventilation",
    "impotence",
    "increased_thirst",
    "incontinence",
    "indigestion",
    "infection",
    "inflammation",
    "insomnia",
    "irregular_heartbeat",
    "irregular_periods",
    "itching",
    "jaundice",
    "joint_pain",
    "joint_stiffness",
    "kidney_pain",
    "lethargy",
    "light_sensitivity",
    "loss_of appetite",
    "loss_of balance",
    "loss_of consciousness",
    "loss_of coordination",
    "loss_of memory",
    "low_blood pressure",
    "low_blood sugar",
    "low-grade_fever",
    "lump_in the breast",
    "memory_loss",
    "menstrual_changes",
    "mood_changes",
    "muscle_aches",
    "muscle_cramps",
    "muscle_weakness",
    "nausea",
    "neck_pain",
    "nervousness",
    "night_sweats",
    "nosebleeds",
    "numbness",
    "oozing",
    "painful_urination",
    "pale_skin",
    "panic_attacks",
    "papules",
    "pelvic_pain",
    "pounding_heartbeat",
    "rapid_heartbeat",
    "rash",
    "redness",
    "ringing_in the ears",
    "runny_nose",
    "sadness",
    "scaly_skin",
    "seizures",
    "sensitive_teeth",
    "shortness_of breath",
    "sinus",
    "sinus_congestion",
    "sinus_pain",
    "skin_discoloration",
    "skin_rash",
    "sneezing",
    "sore_throat",
    "stiffness",
    "stomach_cramps",
    "stomach_pain",
    "stuffy_nose",
    "sweating",
    "swelling",
    "swollen_glands",
    "swollen_joints",
    "tenderness",
    "thirst",
    "tightness_in chest",
    "tingling",
    "tinnitus",
    "tiredness",
    "toothache",
    "trembling",
    "uncontrolled_movements",
    "unexplained_weight loss",
    "urinary_incontinence",
    "urinary_urgency",
    "vertigo",
    "vomiting",
    "warts",
    "weakness",
    "wheezing",
    "wound_drainage",
    "yellowing_of skin and eyes"
] as const;

export const symptomById = {
    "abdominal_cramps": "Abdominal cramps",
    "abdominal_pain": "Abdominal pain",
    "acne": "Acne",
    "agitation": "Agitation",
    "anemia": "Anemia",
    "anxiety": "Anxiety",
    "appetite_loss": "Appetite loss",
    "back_pain": "Back pain",
    "bad_breath": "Bad breath",
    "black_or tarry stools": "Black or tarry stools",
    "bleeding_gums": "Bleeding gums",
    "bloating": "Bloating",
    "blurred_vision": "Blurred vision",
    "breast_lump": "Breast lump",
    "breast_pain": "Breast pain",
    "bruising": "Bruising",
    "burning_urination": "Burning urination",
    "changes_in bowel habits": "Changes in bowel habits",
    "chest_discomfort": "Chest discomfort",
    "chest_pain": "Chest pain",
    "chills": "Chills",
    "cold_intolerance": "Cold intolerance",
    "confusion": "Confusion",
    "constipation": "Constipation",
    "cough": "Cough",
    "cramps": "Cramps",
    "decreased_sweating": "Decreased sweating",
    "delayed_wound healing": "Delayed wound healing",
    "depression": "Depression",
    "diarrhea": "Diarrhea",
    "difficulty_breathing": "Difficulty breathing",
    "difficulty_swallowing": "Difficulty swallowing",
    "discharge": "Discharge",
    "dizziness": "Dizziness",
    "double_vision": "Double vision",
    "dry_mouth": "Dry mouth",
    "dry_skin": "Dry skin",
    "ear_pain": "Ear pain",
    "eczema": "Eczema",
    "edema": "Edema",
    "emotional_instability": "Emotional instability",
    "enlarged_lymph nodes": "Enlarged lymph nodes",
    "enlarged_thyroid gland": "Enlarged thyroid gland",
    "excessive_thirst": "Excessive thirst",
    "excessive_urination": "Excessive urination",
    "eye_pain": "Eye pain",
    "facial_pain": "Facial pain",
    "fainting": "Fainting",
    "fatigue": "Fatigue",
    "fever": "Fever",
    "fingernail_changes": "Fingernail changes",
    "flushing": "Flushing",
    "frequent_infections": "Frequent infections",
    "frequent_urination": "Frequent urination",
    "gas": "Gas",
    "genital_rash": "Genital rash",
    "hair_loss": "Hair loss",
    "hallucinations": "Hallucinations",
    "headache": "Headache",
    "heart_palpitations": "Heart palpitations",
    "hearing_loss": "Hearing loss",
    "heartburn": "Heartburn",
    "hiccups": "Hiccups",
    "high_blood pressure": "High blood pressure",
    "high_blood sugar": "High blood sugar",
    "high_cholesterol": "High cholesterol",
    "hoarseness": "Hoarseness",
    "hot_flashes": "Hot flashes",
    "hyperactivity": "Hyperactivity",
    "hyperventilation": "Hyperventilation",
    "impotence": "Impotence",
    "increased_thirst": "Increased thirst",
    "incontinence": "Incontinence",
    "indigestion": "Indigestion",
    "infection": "Infection",
    "inflammation": "Inflammation",
    "insomnia": "Insomnia",
    "irregular_heartbeat": "Irregular heartbeat",
    "irregular_periods": "Irregular periods",
    "itching": "Itching",
    "jaundice": "Jaundice",
    "joint_pain": "Joint pain",
    "joint_stiffness": "Joint stiffness",
    "kidney_pain": "Kidney pain",
    "lethargy": "Lethargy",
    "light_sensitivity": "Light sensitivity",
    "loss_of appetite": "Loss of appetite",
    "loss_of balance": "Loss of balance",
    "loss_of consciousness": "Loss of consciousness",
    "loss_of coordination": "Loss of coordination",
    "loss_of memory": "Loss of memory",
    "low_blood pressure": "Low blood pressure",
    "low_blood sugar": "Low blood sugar",
    "low-grade_fever": "Low-grade fever",
    "lump_in the breast": "Lump in the breast",
    "memory_loss": "Memory loss",
    "menstrual_changes": "Menstrual changes",
    "mood_changes": "Mood changes",
    "muscle_aches": "Muscle aches",
    "muscle_cramps": "Muscle cramps",
    "muscle_weakness": "Muscle weakness",
    "nausea": "Nausea",
    "neck_pain": "Neck pain",
    "nervousness": "Nervousness",
    "night_sweats": "Night sweats",
    "nosebleeds": "Nosebleeds",
    "numbness": "Numbness",
    "oozing": "Oozing",
    "painful_urination": "Painful urination",
    "pale_skin": "Pale skin",
    "panic_attacks": "Panic attacks",
    "papules": "Papules",
    "pelvic_pain": "Pelvic pain",
    "pounding_heartbeat": "Pounding heartbeat",
    "rapid_heartbeat": "Rapid heartbeat",
    "rash": "Rash",
    "redness": "Redness",
    "ringing_in the ears": "Ringing in the ears",
    "runny_nose": "Runny nose",
    "sadness": "Sadness",
    "scaly_skin": "Scaly skin",
    "seizures": "Seizures",
    "sensitive_teeth": "Sensitive teeth",
    "shortness_of breath": "Shortness of breath",
    "sinus": "Sinus",
    "sinus_congestion": "Sinus congestion",
    "sinus_pain": "Sinus pain",
    "skin_discoloration": "Skin discoloration",
    "skin_rash": "Skin rash",
    "sneezing": "Sneezing",
    "sore_throat": "Sore throat",
    "stiffness": "Stiffness",
    "stomach_cramps": "Stomach cramps",
    "stomach_pain": "Stomach pain",
    "stuffy_nose": "Stuffy nose",
    "sweating": "Sweating",
    "swelling": "Swelling",
    "swollen_glands": "Swollen glands",
    "swollen_joints": "Swollen joints",
    "tenderness": "Tenderness",
    "thirst": "Thirst",
    "tightness_in chest": "Tightness in chest",
    "tingling": "Tingling",
    "tinnitus": "Tinnitus",
    "tiredness": "Tiredness",
    "toothache": "Toothache",
    "trembling": "Trembling",
    "uncontrolled_movements": "Uncontrolled movements",
    "unexplained_weight loss": "Unexplained weight loss",
    "urinary_incontinence": "Urinary incontinence",
    "urinary_urgency": "Urinary urgency",
    "vertigo": "Vertigo",
    "vomiting": "Vomiting",
    "warts": "Warts",
    "weakness": "Weakness",
    "wheezing": "Wheezing",
    "wound_drainage": "Wound drainage",
    "yellowing_of skin and eyes": "Yellowing of skin and eyes"
} as const;