import { FC, useEffect, useState } from "react";
import { chrapaSrc, doctorSrc } from "../assets";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Doctor: FC<{text?: string, talking: boolean}> = ({text, talking}) => {
    const [mouthOpen, setMouthOpen] = useState(false)
    const dim = useWindowDimensions()

    useEffect(() => {
        const i = setInterval(() => setMouthOpen(old => !old), 400)

        return () => clearInterval(i)
    }, [])

    return (
        <div className="relative h-[75%] w-max" style={{ display: dim.width < 720 ? "none" : "block"}}>
            <div className="absolute -left-[10%] -top-[5%] transition-all">
            <div className="relative bg-white text-green text-md rounded-[100%] p-4 text-center transition-all">
                {text}
            </div>
            </div>
            <img src={chrapaSrc} className={['w-[10%] absolute top-[25%] left-[35%] transition-all duration-200', (!mouthOpen && talking) ? 'scale-y-[0.5]' : 'scale-y-1'].join(" ")} />
            <img src={doctorSrc} className="w-full h-full object-contain  -mb-px" />
        </div>
    )
}

export default Doctor