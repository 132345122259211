import axios from "axios";
import { IUser } from "../types/user";

const api = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? 'http://localhost:5001/myaimd/us-central1/default-api' : 'https://us-central1-myaimd.cloudfunctions.net/default-api',
});

class Api {
    getDiagnosis = async (user: IUser, symptoms: string[]) => {
        return api.post("/symptoms", {user, symptoms}).then((res) => res.data as string);
    }
}

export default new Api();