import { FC, useState } from 'react'
import { symptomById, symptomIds } from '../../assets/symptomList'

const CurrentSymptoms: FC<{ onSubmit: (symptoms: string[]) => void }> = ({ onSubmit }) => {
  const [symptoms, setSymptoms] = useState<string[]>([])

  const addCondition = (condition: string) => {
    setSymptoms((old) => [condition, ...old.filter((c) => c !== condition)])
  }

  const removeCondition = (condition: string) => {
    setSymptoms((old) => old.filter((c) => c !== condition))
  }

  return (
    <div className="py-3 relative bg-green-secondary rounded-2xl w-full max-w-[500px] h-[calc(100%-32px)] mb-4">
      <div className="w-full h-[calc(100%-0px)]">
        <div className="flex flex-col w-full px-3 h-[calc(100%)]">
          <h5 className="text-white mt-2 mb-2">Your symptoms</h5>
          <div className="-left-3 w-[calc(100%+24px)] relative bg-white h-1" />
          <div className="flex gap-2 rounded-xl mt-3 bg-white p-2 flex-wrap">
            {symptoms?.map((condition) => (
              <span key={condition} onClick={() => removeCondition(condition)} className="tag bg-slate-200 text-blue">
                {symptomById[condition as keyof typeof symptomById]}
              </span>
            ))}
            {!symptoms?.length && <span className="text-blue text-xl font-bold w-full text-center">No symptoms</span>}
          </div>
          <div className="-left-3 w-[calc(100%+24px)] relative bg-white h-1 mt-3" />
          <div className="grow overflow-y-auto bg-white rounded-xl my-3">
            <div className="flex gap-2 p-2 flex-wrap h-max">
              {symptomIds?.map(
                (condition) =>
                  !symptoms?.includes(condition) && (
                    <span key={condition} onClick={() => addCondition(condition)} className="tag bg-slate-200 text-blue">
                      {symptomById[condition as keyof typeof symptomById]}
                    </span>
                  )
              )}
            </div>
          </div>
          <button disabled={!symptoms.length} className="button-primary !py-4 justify-center hover:bg-slate-100" onClick={() => onSubmit(symptoms)}>Get Diagnosed</button>
        </div>
      </div>
    </div>
  )
}

export default CurrentSymptoms
