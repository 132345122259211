import { FC } from 'react'
import useStore from '../hooks/useStore'
import { CardsIcon, CloseIcon, UserIcon, downloadAppleSrc, downloadGoogleSrc } from '../assets'
import useAwaitableModal from '../hooks/useAwaitableModal'
import MedicalFileModal from './modals/MedicalFile'
import CaseLogModal from './modals/CaseLog'
import { iOS, isApp } from '../constants'

const Sidebar: FC<{ open: boolean; setOpen: (open: boolean) => void }> = ({ open, setOpen }) => {
  const className = [
    'fixed flex flex-col space-y-3 py-5 px-3 right-0 top-0 h-screen w-full max-w-[300px] z-[1000] bg-slate-100 border-l border-l-8 border-l-blue transition-all',
    open ? 'translate-x-0' : 'translate-x-full',
  ].join(' ')
  const backdropClassName = ['fixed inset-0 flex items-center justify-center z-[100]', open ? 'backdrop-blur-sm' : 'backdrop-blur-none pointer-events-none'].join(' ')
  const [store] = useStore()
  const [updateMedicalFile, MedicalFile] = useAwaitableModal(MedicalFileModal, {})
  const [openCaseLog, CaseLog] = useAwaitableModal(CaseLogModal, {})

  return (
    <>
      <MedicalFile />
      <CaseLog />
      <div className={backdropClassName} onClick={() => setOpen(false)}></div>
      <div className={className}>
        <div className="flex items-center space-x-3 mb-3">
          {store.user && <CloseIcon className="clickable-icon h-8 w-8 p-1" onClick={() => setOpen(false)} />}
          <h4 className="text-green text-left">Menu</h4>
        </div>
        <div className="group flex w-full p-2 justify-start bg-slate-200 rounded-md space-x-3 cursor-pointer hover:opacity-[80%]" onClick={() => updateMedicalFile().finally(() => {})}>
          <UserIcon className="fill-green" />
          <span className="text-green">Medical file</span>
        </div>
        {!store.cases.length || (
          <div className="group flex w-full p-2 justify-start bg-slate-200 rounded-md space-x-3 cursor-pointer hover:opacity-[80%]" onClick={() => openCaseLog().finally(() => {})}>
            <CardsIcon className="fill-green" />
            <span className="text-green">Case log</span>
          </div>
        )}
        {!isApp() && (
          <>
          <div className='grow' />
          <div className="flex flex-col justify-end items-center space-y-2">
            {iOS() ? (
              <>
                <img className="h-12 cursor-pointer" src={downloadAppleSrc} onClick={() => window.open("https://apps.apple.com/us/app/aimd/id6449625712", "_blank")} />
                <img className="h-12 cursor-pointer" src={downloadGoogleSrc} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.repka.aimd", "_blank")} />
              </>
            ) : (
              <>
                <img className="h-12 cursor-pointer" src={downloadGoogleSrc} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.repka.aimd", "_blank")} />
                <img className="h-12 cursor-pointer" src={downloadAppleSrc} onClick={() => window.open("https://apps.apple.com/us/app/aimd/id6449625712", "_blank")} />
              </>
            )}
          </div>
          </>
        )}
      </div>
    </>
  )
}

export default Sidebar
