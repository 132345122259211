import { FC } from 'react'
import { createPortal } from 'react-dom'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { CloseIcon } from '../assets'

interface props {
  open: boolean
  close: (...args: any) => void
}

const Modal: FC<React.PropsWithChildren<props>> = ({ children, open, close }) => {
  const dim = useWindowDimensions()
  if (!open) return null
  return createPortal(
    <div style={{ height: window.innerHeight }} className="fixed inset-0 backdrop-blur-sm flex items-center justify-center h-screen z-[10000000000] overflow-hidden" onClick={close}>
      <div onClick={(e) => e.stopPropagation()} className="m-4 max-h-full h-max relative" style={{ maxHeight: dim.height}}>
                  <CloseIcon className="clickable-icon h-8 w-8 p-1 bg-slate-200 absolute z-[10000] -right-2 -top-2" onClick={close} />
                  {children}
                  </div>
    </div>,
    document.body
  )
}

export default Modal
