import { FC } from 'react'
import { symptomById } from '../../assets/symptomList'
import { ICase } from '../../types/user'
import FullLoader from '../loaders/FullLoader'

const Diagnosis: FC<{ diagnosis: ICase; onNext?: () => void }> = ({ diagnosis, onNext }) => {
  const symptoms = diagnosis?.symptoms
  return (
    <div className="py-3 relative bg-green-secondary rounded-2xl w-full max-w-[500px] h-[calc(100%-32px)] mb-4">
      <div className="w-full h-[calc(100%-0px)]">
        <div className="flex flex-col w-full px-3 h-[calc(100%-0px)]">
          <h4 className="text-white text-4xl py-2">Case</h4>
          <div className="-left-3 w-[calc(100%+24px)] relative bg-white h-1" />
          <h5 className="text-white mt-2 mb-2">Symptoms</h5>
          <div className="flex gap-2 rounded-xl mt-3 bg-white p-2 flex-wrap">
            {symptoms?.map((condition) => (
              <span key={condition} className="tag bg-slate-200 text-blue !cursor-default">
                {symptomById[condition as keyof typeof symptomById]}
              </span>
            ))}
            {!symptoms?.length && <span className="text-blue text-xl font-bold w-full text-center">No symptoms</span>}
          </div>
          <div className="-left-3 w-[calc(100%+24px)] relative bg-white h-1 mt-3" />
          <h5 className="text-white mt-2 mb-2">Diagnosis</h5>
          <div className="p-5 bg-white rounded-xl grow flex h-full overflow-y-auto text-green overflow-x-hidden w-full">
            {diagnosis.response ? (
              <div className="h-full">
                <pre className="whitespace-pre-line">{diagnosis.response}</pre>
              </div>
            ) : (
              <FullLoader />
            )}
          </div>
          {onNext !== undefined && (
            <button className="button-primary !py-4 justify-center hover:bg-slate-100 mt-4" onClick={onNext}>
              Next Case
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Diagnosis
