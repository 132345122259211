import { FC } from "react";
import Loader from "./Loader";

const FullLoader: FC = () => {
    return (
      <div className="w-full h-full grow flex items-center justify-center">
        <Loader />
      </div>
    )
}

export default FullLoader
