import { FC, useState } from 'react'
import { ICase } from '../../../types/user'
import Diagnosis from '../../Diagnosis';

const Case: FC<{ log: ICase; number: number }> = ({ log, number }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <div className="flex items-center justify-between bg-slate-100 grow items-start px-3 py-2 rounded-md cursor-pointer hover:bg-slate-200">
        <div className="flex flex-col space-y-1">
          <span>Case #{number}</span>
          <span className="text-xs">{new Date(log.date).toLocaleTimeString()}</span>
        </div>
        <button className="button-primary hover:bg-white capitalize" onClick={() => setExpanded(old => !old)}>{expanded ? "Collapse" : "View"}</button>
      </div>
        {expanded && (
            <Diagnosis diagnosis={log} />
        )}
    </>
  )
}

export default Case
