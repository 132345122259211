import { FC, useCallback, useMemo, useState } from 'react'

type ExtendedProps<Result = unknown, Data = unknown> = { resolve: (val?: Result) => void; onCancel: () => void; initialData: Data; open: boolean }

export type AwaitableModal<Result = unknown, Data = unknown> = FC<ExtendedProps<Result, Data>>

const useAwaitableModal: <Result = unknown, Data = unknown>(Modal: AwaitableModal<Result, Data>, initialData: Data) => [(data?: Data) => Promise<Result>, () => JSX.Element] = (Modal, initialData) => {
  const [requesting, setRequesting] = useState(false)
  const [resolve, setResolve] = useState<(val: any) => void>()
  const [reject, setReject] = useState<() => void>()
  const [data, setData] = useState(initialData)

  const request = useCallback(async (data?: typeof initialData) => {
    if (requesting) return
    setData(data || initialData)
    const newPromise = new Promise<any>((_resolve, _reject) => {
      setResolve(() => _resolve)
      setReject(() => _reject)
    }).finally(() => {
      setRequesting(false)
      setResolve(undefined)
      setReject(undefined)
    })
    setRequesting(true)
    return newPromise
  }, [initialData, requesting])

  const ModalC = useMemo(() => {
    return () => <Modal open={requesting} resolve={(val?: any) => resolve?.(val as any)} onCancel={() => reject?.()} initialData={data} />
  }, [requesting, resolve, reject, data, Modal])

  return [request, ModalC]
}

export default useAwaitableModal
