import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import StoreContext from './context'
import { IStore } from './types'
import Splash from '../../components/Splash'
import useAwaitableModal from '../../hooks/useAwaitableModal'
import TermsModal from '../../components/modals/TermsModal'
import firebase from '../../config/firebase'
import { setAnalyticsCollectionEnabled } from 'firebase/analytics'

const StoreContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [store, _setStore] = useState<IStore>(JSON.parse(localStorage.getItem('store') || '{"cases":[], "consent": false}'))
  const [loaded, setLoaded] = useState(false)
  const [requestConsent, RequestModal] = useAwaitableModal(TermsModal, {})

  const setStore = useCallback((action: ((old: IStore) => IStore) | IStore) => {
    if (typeof action === 'function') {
      return _setStore((old) => {
        const result = action(old)
        localStorage.setItem('store', JSON.stringify(result))
        return result
      })
    }
    setStore(() => action)
  }, [])

  useEffect(() => {
    if (!store.consent) {
      setAnalyticsCollectionEnabled(firebase.analytics, false)
      requestConsent()
        .then(() => setStore((old) => ({ ...old, consent: true })))
        .catch(() => window.close())
    } else {
      setAnalyticsCollectionEnabled(firebase.analytics, true)
    }
  }, [store.consent, setStore])

  const onCanLoad = () => {
    setTimeout(() => setLoaded(true), 2000)
  }

  return (
    <StoreContext.Provider value={{ store, setStore }}>
      {!store.consent && window.parent === window && <Splash loaded={loaded} onCanLoad={onCanLoad} />}
      {!store.consent && <RequestModal />}
      {children}
    </StoreContext.Provider>
  )
}

export default StoreContextProvider
