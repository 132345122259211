import { CheckIcon } from '../../assets'
import { AwaitableModal } from '../../hooks/useAwaitableModal'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import Modal from '../Modal'

const TermsModal: AwaitableModal = ({ resolve, onCancel, open }) => {
  const dim = useWindowDimensions()
  return (
    <Modal open={open} close={onCancel}>
      <div className="bg-green rounded-2xl max-w-[500px] w-full p-8 flex flex-col items-center drop-shadow-md overflow-y-auto" style={{ maxHeight: dim.height - 32, width: dim.width - 32}}>
        <h1 className='text-white'>Terms of Service</h1>
        <div className='text-wrapper mt-8 text-green'>
          We're collecting data to improve our services. This includes information on how users interact with our platform and demographics. All data collected will be kept confidential and used in
          accordance with our privacy policy. Thank you for helping us improve our services. The recommendations provided are for informational purposes only. It is recommended to seek professional
          medical advice before taking any actions based on the responses. The user assumes all responsibility for their healthcare decisions.
        </div>
        <button className="button-primary mt-5" onClick={() => resolve()}>
            <CheckIcon className="h-4" />
            I Consent
        </button>
      </div>
    </Modal>
  )
}

export default TermsModal
