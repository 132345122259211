export const preexistingIds = [
    "acid_reflux",
    "acne",
    "addisons_disease",
    "allergies",
    "alzheimers_disease",
    "anemia",
    "anxiety",
    "arrhythmia",
    "arthritis",
    "atherosclerosis",
    "atrial_fibrillation",
    "adhd",
    "autoimmune_diseases",
    "back_pain",
    "bipolar_disorder",
    "blood_clots",
    "brain_injury",
    "cataracts",
    "celiac_disease",
    "cerebral_palsy",
    "chronic_fatigue syndrome",
    "chronic_kidney disease",
    "chronic_pain",
    "cirrhosis",
    "coeliac_disease",
    "congestive_heart failure",
    "crohns_disease",
    "cystic_fibrosis",
    "dvt",
    "dementia",
    "dental_issues",
    "depressive_disorders",
    "dermatitis",
    "developmental_disabilities",
    "diverticulitis",
    "down_syndrome",
    "dyslexia",
    "eczema",
    "endometriosis",
    "erectile_dysfunction",
    "fibromyalgia",
    "food_allergies",
    "gerd",
    "glaucoma",
    "gout",
    "graves_disease",
    "gum_disease",
    "hashimotos_disease",
    "heart_attack",
    "heart_disease",
    "hepatitis",
    "herniated_disc",
    "herpes",
    "high_blood pressure",
    "high_cholesterol",
    "hypertension",
    "hyperthyroidism",
    "hypothyroidism",
    "ibd",
    "ibs",
    "joint_pain",
    "lactose_intolerance",
    "laryngitis",
    "lupus",
    "lyme_disease",
    "macular_degeneration",
    "malaria",
    "melanoma",
    "meningitis",
    "menopause",
    "migraines",
    "mitral_valve prolapse",
    "multiple_myeloma",
    "multiple_sclerosis",
    "muscular_dystrophy",
    "narcolepsy",
    "nephrotic_syndrome",
    "neurological_disorders",
    "ocd",
    "osteoarthritis",
    "osteoporosis",
    "ovarian_cancer",
    "overactive_bladder",
    "pancreatitis",
    "parkinsons_disease",
    "peptic_ulcer disease",
    "peripheral_artery disease",
    "pcos",
    "ptsd",
    "prostate_cancer",
    "psoriasis",
    "pulmonary_embolism",
    "renal_failure",
    "restless_legs syndrome",
    "rheumatoid_arthritis",
    "rosacea",
    "sarcoidosis",
    "scoliosis",
    "seizures",
    "sjogrens_syndrome",
    "skin_cancer",
    "sleep_apnea",
    "spina_bifida",
    "spinal_cord injury",
    "stroke",
    "sle",
    "testicular_cancer",
    "thyroid_cancer",
    "tinnitus",
    "tourettes_syndrome",
    "tuberculosis",
    "ulcerative_colitis",
    "utis",
    "varicose_veins",
    "vertigo",
    "von_willebrand disease",
    "wilsons_disease",
    "xeroderma_pigmentosum",
    "yellow_fever",
    "zika_virus"
] as const;

export const preexistingById = {
    "acid_reflux": "Acid reflux",
    "acne": "Acne",
    "addisons_disease": "Addison's disease",
    "allergies": "Allergies",
    "alzheimers_disease": "Alzheimer's disease",
    "anemia": "Anemia",
    "anxiety": "Anxiety",
    "arrhythmia": "Arrhythmia",
    "arthritis": "Arthritis",
    "atherosclerosis": "Atherosclerosis",
    "atrial_fibrillation": "Atrial fibrillation",
    "adhd": "Attention deficit hyperactivity disorder (ADHD)",
    "autoimmune_diseases": "Autoimmune diseases",
    "back_pain": "Back pain",
    "bipolar_disorder": "Bipolar disorder",
    "blood_clots": "Blood clots",
    "brain_injury": "Brain injury",
    "cataracts": "Cataracts",
    "celiac_disease": "Celiac disease",
    "cerebral_palsy": "Cerebral palsy",
    "chronic_fatigue syndrome": "Chronic fatigue syndrome",
    "chronic_kidney disease": "Chronic kidney disease",
    "chronic_pain": "Chronic pain",
    "cirrhosis": "Cirrhosis",
    "coeliac_disease": "Coeliac disease",
    "congestive_heart failure": "Congestive heart failure",
    "crohns_disease": "Crohn's disease",
    "cystic_fibrosis": "Cystic fibrosis",
    "dvt": "Deep vein thrombosis (DVT)",
    "dementia": "Dementia",
    "dental_issues": "Dental issues",
    "depressive_disorders": "Depressive disorders",
    "dermatitis": "Dermatitis",
    "developmental_disabilities": "Developmental disabilities",
    "diverticulitis": "Diverticulitis",
    "down_syndrome": "Down syndrome",
    "dyslexia": "Dyslexia",
    "eczema": "Eczema",
    "endometriosis": "Endometriosis",
    "erectile_dysfunction": "Erectile dysfunction",
    "fibromyalgia": "Fibromyalgia",
    "food_allergies": "Food allergies",
    "gerd": "Gastroesophageal reflux disease (GERD)",
    "glaucoma": "Glaucoma",
    "gout": "Gout",
    "graves_disease": "Graves' disease",
    "gum_disease": "Gum disease",
    "hashimotos_disease": "Hashimoto's disease",
    "heart_attack": "Heart attack",
    "heart_disease": "Heart disease",
    "hepatitis": "Hepatitis",
    "herniated_disc": "Herniated disc",
    "herpes": "Herpes",
    "high_blood pressure": "High blood pressure",
    "high_cholesterol": "High cholesterol",
    "hypertension": "Hypertension",
    "hyperthyroidism": "Hyperthyroidism",
    "hypothyroidism": "Hypothyroidism",
    "ibd": "Inflammatory bowel disease (IBD)",
    "ibs": "Irritable bowel syndrome (IBS)",
    "joint_pain": "Joint pain",
    "lactose_intolerance": "Lactose intolerance",
    "laryngitis": "Laryngitis",
    "lupus": "Lupus",
    "lyme_disease": "Lyme disease",
    "macular_degeneration": "Macular degeneration",
    "malaria": "Malaria",
    "melanoma": "Melanoma",
    "meningitis": "Meningitis",
    "menopause": "Menopause",
    "migraines": "Migraines",
    "mitral_valve prolapse": "Mitral valve prolapse",
    "multiple_myeloma": "Multiple myeloma",
    "multiple_sclerosis": "Multiple sclerosis",
    "muscular_dystrophy": "Muscular dystrophy",
    "narcolepsy": "Narcolepsy",
    "nephrotic_syndrome": "Nephrotic syndrome",
    "neurological_disorders": "Neurological disorders",
    "ocd": "Obsessive-compulsive disorder (OCD)",
    "osteoarthritis": "Osteoarthritis",
    "osteoporosis": "Osteoporosis",
    "ovarian_cancer": "Ovarian cancer",
    "overactive_bladder": "Overactive bladder",
    "pancreatitis": "Pancreatitis",
    "parkinsons_disease": "Parkinson's disease",
    "peptic_ulcer disease": "Peptic ulcer disease",
    "peripheral_artery disease": "Peripheral artery disease",
    "pcos": "Polycystic ovary syndrome (PCOS)",
    "ptsd": "Post-traumatic stress disorder (PTSD)",
    "prostate_cancer": "Prostate cancer",
    "psoriasis": "Psoriasis",
    "pulmonary_embolism": "Pulmonary embolism",
    "renal_failure": "Renal failure",
    "restless_legs syndrome": "Restless legs syndrome",
    "rheumatoid_arthritis": "Rheumatoid arthritis",
    "rosacea": "Rosacea",
    "sarcoidosis": "Sarcoidosis",
    "scoliosis": "Scoliosis",
    "seizures": "Seizures",
    "sjogrens_syndrome": "Sjogren's syndrome",
    "skin_cancer": "Skin cancer",
    "sleep_apnea": "Sleep apnea",
    "spina_bifida": "Spina bifida",
    "spinal_cord injury": "Spinal cord injury",
    "stroke": "Stroke",
    "sle": "Systemic lupus erythematosus (SLE)",
    "testicular_cancer": "Testicular cancer",
    "thyroid_cancer": "Thyroid cancer",
    "tinnitus": "Tinnitus",
    "tourettes_syndrome": "Tourette's syndrome",
    "tuberculosis": "Tuberculosis",
    "ulcerative_colitis": "Ulcerative colitis",
    "utis": "Urinary tract infections (UTIs)",
    "varicose_veins": "Varicose veins",
    "vertigo": "Vertigo",
    "von_willebrand disease": "Von Willebrand disease",
    "wilsons_disease": "Wilson's disease",
    "xeroderma_pigmentosum": "Xeroderma pigmentosum",
    "yellow_fever": "Yellow fever",
    "zika_virus": "Zika virus"
} as const;