export const isApp = () => Boolean((window as any).ReactNativeWebView)

export const iOS = () => {
  if (typeof navigator === "undefined") {
    return true
  }

  return [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}