import { AwaitableModal } from '../../../hooks/useAwaitableModal'
import useStore from '../../../hooks/useStore'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import Modal from '../../Modal'
import Case from './Case'

const CaseLogModal: AwaitableModal = ({ open, onCancel }) => {
  const [store, setStore] = useStore()
  const dim = useWindowDimensions()

  return (
    <Modal open={open} close={onCancel}>
      <div className="p-3 space-y-3 flex flex-col relative bg-green-secondary rounded-2xl max-w-[500px] overflow-y-auto" style={{ maxHeight: dim.height - 32, width: dim.width - 32}}>
            <h4 className="text-white text-2xl">Case log</h4>
            <div className="max-h-full overflow-y-auto">
            <div className="flex flex-col grow bg-white w-full rounded-2xl p-6 justify-between space-y-4 grow h-full">
              {store.cases?.map((log, index) => (
                <Case key={log.id} log={log} number={store.cases.length - index} />
              ))}
            </div>
        </div>
      </div>
    </Modal>
  )
}

export default CaseLogModal
