import { FC, useState } from "react";
import useStore from "./hooks/useStore";
import { CardEditIcon, logoSrc } from "./assets";
import Sidebar from "./components/Sidebar";
import CurrentSymptoms from "./components/CurrentSymptoms";
import Doctor from "./components/Doctor";
import { ICase } from "./types/user";
import {v4} from "uuid";
import Diagnosis from "./components/Diagnosis";
import api from "./api";
import useWindowDimensions from "./hooks/useWindowDimensions";

const App: FC = () => {
  const dim = useWindowDimensions()
  const [store, setStore] = useStore();
  const [hadActiveCase, setHadActiveCase] = useState(false)
  const [activeCase, setActiveCase] = useState<ICase>();
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const onSubmit = (symptoms: string[]) => {
    if ((activeCase && !activeCase.response) || !store.user) return;
    const c = {
      id: v4(),
      symptoms,
      user: store.user,
      response: "",
      date: new Date(),
    }
    setActiveCase(c)
    api.getDiagnosis(store.user, symptoms).then(response => {
      setActiveCase({...c, response})
      setStore(old => ({...old, cases: [{...c, response}, ...old.cases]}))
    })
  }

  const onNext = () => {
    setActiveCase(undefined)
    setHadActiveCase(true)
  }

  const sc = Math.min(dim.height * 0.2, 128)

  return (
    <div className="bg-white flex flex-col overflow-hidden" style={{...dim, maxHeight: dim.height}}>
      <div className="relative flex flex-col overflow-hidden h-full">
      <Sidebar setOpen={setSidebarOpen} open={sidebarOpen || (!store.user && store.consent)} />
      <header className="flex justify-between items-center" style={{ height: sc, padding: sc * 6/32 }}>
        <img src={logoSrc} className="h-full" />
        <CardEditIcon className="clickable-icon" onClick={() => setSidebarOpen(true)}/>
      </header>
      <div className="overflow-hidden flex grow">
        <div className="flex h-full bg-blue-secondary items-end justify-center gap-3 px-4 w-full">
          <Doctor talking={false} text={!activeCase ? hadActiveCase ? "Whats your issue?" :"Hello, what seems to be the issue?" : !activeCase.response ? ".........." : "Here are your results!"} />
          {activeCase ? <Diagnosis diagnosis={activeCase} onNext={onNext} /> : <CurrentSymptoms onSubmit={onSubmit} />}
        </div>
      </div>
      <footer className="flex shrink-0 bg-blue" style={{ height: sc / 2}} />
      </div>
    </div>
  );
}

export default App;
