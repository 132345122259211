import { AwaitableModal } from '../../../hooks/useAwaitableModal'
import Modal from '../../Modal'
import useStore from '../../../hooks/useStore'
import { IStore } from '../../../contexts/StoreContext/types'
import { preexistingById, preexistingIds } from '../../../assets/preexistingList'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const MedicalFileModal: AwaitableModal = ({ open, onCancel }) => {
  const [store, setStore] = useStore()
  const dim = useWindowDimensions()

  const onAgeChange = (age: number) => {
    setStore((old: IStore) => old && { ...old, user: { ...old.user, age } })
  }

  const onGenderChange = (gender: 'male' | 'female') => {
    setStore((old: IStore) => old && { ...old, user: { ...old.user, gender } })
  }

  const addCondition = (condition: string) => {
    setStore(
      (old: IStore) =>
        old && {
          ...old,
          user: { ...old.user, preexistingConditions: [condition, ...(old.user?.preexistingConditions?.filter((c) => c !== condition) || [])] },
        }
    )
  }

  const removeCondition = (condition: string) => {
    setStore(
      (old: IStore) =>
        old && {
          ...old,
          user: { ...old.user, preexistingConditions: [...(old.user?.preexistingConditions?.filter((c) => c !== condition) || [])] },
        }
    )
  }

  return (
    <Modal open={open} close={onCancel}>
      <div className="py-3 relative bg-green-secondary rounded-2xl w-full max-w-[500px] overflow-y-auto" style={{ maxHeight: dim.height - 32, width: dim.width - 32}}>
        <div className="w-full">
          <div className="flex flex-col w-full px-3">
            <div className="flex space-x-3">
              <div className="flex flex-col bg-white rounded-tl-2xl justify-between p-6 space-y-4 grow">
                <h4>Age</h4>
                <input type="number" value={store.user?.age} min={0} max={99} step={1} onChange={(e) => onAgeChange(e.currentTarget.valueAsNumber)} />
              </div>
              <div className="flex flex-col bg-white rounded-tr-2xl p-6 justify-between space-y-4 grow">
                <h4>Sex</h4>
                <div className="flex items-center justify-evenly">
                  <div className="flex flex-col space-y-3 grow items-center px-3">
                    <span>Male</span>
                    <input type="radio" checked={store.user?.gender === "male"} name="gender" value={'male'} onChange={(e) => e.currentTarget.checked && onGenderChange("male")} />
                  </div>
                  <div className="flex flex-col space-y-3 grow items-center px-3">
                    <span>Female</span>
                    <input type="radio" checked={store.user?.gender === "female"} name="gender" value={'female'} onChange={(e) => e.currentTarget.checked && onGenderChange("female")} />
                  </div>
                </div>
              </div>
            </div>
            <h5 className="text-white mt-8 mb-2">Preexisting medical conditions</h5>
            <div className="-left-3 w-[calc(100%+24px)] relative bg-white h-1" />
            <div className="flex gap-2 rounded-xl mt-3 bg-white p-2 flex-wrap">
              {store.user?.preexistingConditions?.map((condition) => (
                <span key={condition} onClick={() => removeCondition(condition)} className="tag bg-slate-200 text-blue">{preexistingById[condition as keyof typeof preexistingById]}</span>
              ))}
              {!store.user?.preexistingConditions?.length && <span className="text-blue text-xl font-bold w-full text-center">No conditions</span>}
            </div>
            <div className="-left-3 w-[calc(100%+24px)] relative bg-white h-1 mt-3" />
            <div className="flex gap-2 rounded-xl mt-3 bg-white p-2 flex-wrap">
              {preexistingIds?.map((condition) => !store.user?.preexistingConditions?.includes(condition) && <span key={condition} onClick={() => addCondition(condition)} className="tag bg-slate-200 text-blue">{preexistingById[condition]}</span>)}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default MedicalFileModal
